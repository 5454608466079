import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import {media} from 'utils/Media'
import MainNav from 'components/functional/mainNav'
import SingleNews from 'components/functional/singleNews'
import SmallTitle from 'components/functional/smallTitle'
import Layout from 'components/layout'
import ReactPlayer from "react-player";
import {StaticImage} from "gatsby-plugin-image";

const metaData = {
    title: 'Robert De Wynter - 3rd October 1944 - 25th December 2022',
    description: 'We are deeply saddened by the news of Robert De Wynter’s passing over Christmas.',
    image: 'images/news/RD_Cover_Pic.jpg'
}

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 620px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }
  
  .app-img {
    margin-bottom: 1rem;
  }
`
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h3 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
  }

  .body {
    margin-top: 1rem;

    p {
      margin-bottom: 1rem;

      strong {
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`
const LatestNews = ({data}) => {
    return (
        <Layout title={metaData.title} description={metaData.description} image={metaData.image}>
            <SmallTitle title="Robert De Wynter" dnd/>
            <MainNav alt/>
            <NewsBody>
                <div className="news">
                    <SingleNewsStyles>
                        <StaticImage
                            src="../../images/news/RD_Cover_Pic.jpg"
                            alt="ROBERT DE WYNTER"
                            className="app-img"
                        />
                        <h3>Robert De Wynter<br/>
                            3rd October 1944  - 25th December 2022</h3>
                        <h3>&nbsp;</h3>
                        <h3>By James Charrington, CEO, Dewynters.</h3>
                        <div className="body">
                            <p>We are deeply saddened by the news of Robert De Wynter&rsquo;s passing
                                over Christmas.</p>
                            <p>Robert had a huge influence on so many people&rsquo;s working lives; I am lucky to be one
                                of them. I first heard of him when I was working as an intern in New York in 1989. I was
                                photocopying what looked like agendas for a meeting; I looked closer, and the meeting
                                was to discuss campaign plans for a new show with an agency that specialised in theatre
                                marketing.</p>
                            <p>I had no idea what a theatre marketing agency did but was intrigued, and asked if there
                                was an agency in London that did the same work. Yes, I was told &ndash; Dewynters, run
                                by the already legendary Robert De Wynter and Anthony Pye-Jeary. One month later I was
                                sitting in front of Robert, who grilled me about my experiences on Broadway and offered
                                me my first full time job the next day.</p>
                            <p>I started work at the end of Veronica Allen&rsquo;s desk in the Marketing department,
                                spending my days walking the streets of London to put up posters in ticket
                                agents&rsquo; windows and ferrying ads between designers and clients. All around me, agency
                                life buzzed with frenetic energy and excitement under the watchful eyes of Robert and
                                Anthony.</p>
                            <p>Robert&rsquo;s focus and his ambition for Dewynters was evident every day. Each ad he saw
                                could be improved, each deadline could be stretched, each client could be better served,
                                each show could be sold out. He had a knack for solving problems by immediately bringing
                                people together and pushing for a solution &ndash; so much so that the corridors of the
                                agency echoed every ten minutes with Robert&rsquo;s voice, calling different people into
                                his office over the intercom.</p>
                            <p>Work went out of the door at an amazing speed &ndash; it still does &ndash; and Robert
                                saw to it that everything met his and Anthony&rsquo;s high standards every single day.
                                He was relentlessly innovative and entrepreneurial &ndash; always looking for and
                                developing parts of the business that others might not have seen so quickly: media,
                                merchandise, publishing. He was rightly proud of the fact that in the 90s, seven or
                                eight British musicals were touring the US, with Dewynters&rsquo; employees running the
                                merchandise for each show in each city.</p>
                            <StaticImage
                                src="../../images/news/Robert-De-Wynter.jpg"
                                alt="ROBERT DE WYNTER"
                                className="app-img"
                            />
                            <p>Behind this ambition and behind his huge personality was a thoughtful, supportive human
                                being. At the end of a long day, as I was drawing breath, Robert would appear at my desk
                                and talk through campaigns, clients and problems, listening and offering advice. It was
                                his guidance and encouragement that gave me confidence in my own potential; I am forever
                                in his debt for this.</p>
                            <p>Mary Williams, my wonderful boss through the 90s, and I had lunch with Robert just before
                                the pandemic, and it didn&rsquo;t take long for him to provide feedback on the arts
                                marketing industry of 2020. We could tell how much he had loved the competitive,
                                fast-moving world of theatre marketing and how much he missed it; I hope he knew how
                                profound his influence on it was, over thirty eventful years. His opinion on things
                                matters to me now as much as it did back in 1989, and I am proud to have worked with
                                him.</p>
                        </div>
                    </SingleNewsStyles>
                </div>
            </NewsBody>
        </Layout>
    )
}

LatestNews.propTypes = {
    data: PropTypes.shape({
        News: PropTypes.any,
    }),
}

export default LatestNews